import {
  Injectable
} from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  constructor() {}

  defaultPaginationOptions = [25, 50, 100, 2000];

  // builds an object containing pagination state defaults
  public getDefaultPaginationState() {
    return {
      currentPage: 1,
      itemsPerPage: this.defaultPaginationOptions[3],
      itemSort: {
        sortProperty: '',
        reverse: false
      }
    };
  }

}

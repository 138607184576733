import { Observable } from 'rxjs';
import { ApiService } from '@app/core';
import { Injectable } from '@angular/core';
import { PatientEncounterConstants } from '@app/constants/patient-encounter.constants';
import { PatientEncounters, PatientEncounter } from '../../models/patient-encounter.interface';
import { PaginationService } from './pagination.service';
import { convertDateToStringYYYYMMDD } from '@app/core/services/date-utils.service';
import { IClaim } from '@app/private/claims/models/claim.interface';
import {environment} from '../../../../../environments';


@Injectable({
  providedIn: 'root'
})
export class ClaimsHistoryService {
  constructor(private readonly apiService: ApiService,
              private readonly paginationService: PaginationService) { }


  public getBaseClaims(queryObject, paginationState): Observable<PatientEncounters> {
      const searchURL = PatientEncounterConstants.getBasePatientEncounterUrl +
      this.buildQueryStringFromObject(queryObject, {paginationState});
      return this.apiService.get(searchURL);
  }

  public getPatientEncounterFromSelfLink(patientEncounter: PatientEncounter): Observable<IClaim> {
    return this.apiService.get(patientEncounter.self.href);
  }

  public createPatientEncounter(queryObject): Observable<IClaim> {
    const searchUrl = PatientEncounterConstants.getBasePatientEncounterUrl;
    return this.apiService.post(searchUrl, queryObject);
  }

  public getPatientEncounter(trackingNumber: string): Observable<IClaim> {
    const searchUrl = [PatientEncounterConstants.getBasePatientEncounterUrl, trackingNumber].join('/');
    return this.apiService.get(searchUrl);
  }
  public getClaimSummaryFile(accountNumber: string, claimAuth: string, dateOfService: string): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.apiPath}/${PatientEncounterConstants.getClaimSummaryUrl}/?accountNumber=${accountNumber}&claimAuthorization=${claimAuth}&dateOfService=${dateOfService}&reportType=PS2020`;
    return this.apiService.get(url, null, {
      responseType: 'blob' });
  }

  private buildQueryStringFromObject(queryObj, options): string {
      let queryString = '';
      let queryValue;
      let queryPrefix;

      // initialize the queryObj to a blank object if not defined
      queryObj = queryObj || {};

      // initialize options if not provided
      options = options || {};

      // copy the original queryObj before making any additional changes to it
      // queryObj = angular.copy(queryObj);

      // if we have a pagination state, build a query object out of it and merge it into the initial query object
      if (options.paginationState) {
          // if paginationState is set to true, use the default pagination state
          if (options.paginationState === true) {
              options.paginationState = this.paginationService.getDefaultPaginationState();
          }
          queryObj = {...queryObj, ...this.getPaginationStateQueryObject(options.paginationState)};
      }

      for (const queryProp in queryObj) {
          if (queryObj.hasOwnProperty(queryProp)) {
              queryValue = queryObj[queryProp];

              if (this.isValidValue(queryValue, options.allowEmptyString)) {
                  // convert date objects to strings
                  if (queryValue instanceof Date) {
                      queryValue = convertDateToStringYYYYMMDD(queryValue);
                  }

                  // if we already have query string data, the next value needs to be prepended with "&"
                  // otherwise, prepend the first value with "?"
                  queryPrefix = queryString.length > 0 ? '&' : '?';

                  queryString += [queryPrefix, queryProp, '=', queryValue].join('');
              }
          }
      }

      return queryString;
  }

  getPaginationStateQueryObject(paginationState): object {
    // initialize the query object
    const queryObject = {
      pageNumber: 0,
      pageSize: 0,
      sortBy: '',
      sortDirection: ''
    };

    // only set the pageSize/pageNumber if itemsPerPage is defined and greater than 0 (0 indicates All records)
    if (paginationState.itemsPerPage && paginationState.itemsPerPage > 0) {
        queryObject.pageSize = paginationState.itemsPerPage;
        queryObject.pageNumber = paginationState.currentPage - 1; // subtract 1, as the API pageNumber is 0-based
    }

    // if there is an itemSort object with a sortProperty...
    if (paginationState.itemSort && paginationState.itemSort.sortProperty) {
        // set the sortBy param to the sortProperty
        queryObject.sortBy = paginationState.itemSort.sortProperty;

        // if the reverse propery is defined...
        if (!paginationState.itemSort.reverse) {
            // if reverse is true, set sortDirection to 'DESC' (descending), otherwise 'ASC' (ascending)
            queryObject.sortDirection = paginationState.itemSort.reverse === true ? 'DESC' : 'ASC';
        } else {
            // default to ascending if reverse is not defined
            queryObject.sortDirection = 'ASC';
        }
    }
    // return the query object
    return queryObject;
  }

  isValidValue(value, allowEmptyString): boolean {
    // check if value is a string and blank
    if (typeof value === 'string' && value.trim().length === 0) {
        // return the allowEmptyString value as this is an empty string
        return allowEmptyString;
    }

    // not valid if undefined, null, or empty array
    return !(value === undefined || value === null || (value.length !== undefined && value.length === 0));
  }
}

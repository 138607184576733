import {Report} from '@app/shared/components/report/model/report';
import {
  IRetailPatientEligibilityResponse,
  IProductPackage,
  IReservation
} from './../models/eligibility-payload.interface';
import {IVerbalization} from '@app/core/models/verbalization.interface';
import {Injectable} from '@angular/core';
import {isStringNullUndefinedOrEmpty} from '@app/shared/helpers/utility-functions';
import {
  IReportGroup,
  IReportGroupLineItem
} from '@app/shared/components/report/model/report-group.interface';
import {PatientEligibilityReportConstants, PlanDetailOrder} from '@app/constants/patient-eligibility-report.constants';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {Reports} from '@app/shared/components/report/model/reports';

@Injectable({
  providedIn: 'root'
})
export class ReportMapper {

  public mapVerbalizationsFromProductPackageToReport(verbalizationFromPbev: IVerbalization, reportForProductPackage: Report): void {
    if (this.verbalizationItemHasFooterContent(verbalizationFromPbev, reportForProductPackage.prcReport)) {
      this.mapFooter(verbalizationFromPbev, reportForProductPackage);
    } else if (this.verbalizationItemHasHeaderContent(verbalizationFromPbev)) {
      this.mapHeader(verbalizationFromPbev, reportForProductPackage);
    } else if (this.verbalizationItemHasBenefitContent(verbalizationFromPbev)) {
      this.mapBenefit(verbalizationFromPbev, reportForProductPackage);
    } else if (this.verbalizationItemHasGroupLabelAndGroupName(verbalizationFromPbev)) {
      this.mapGroup(verbalizationFromPbev, reportForProductPackage);
    }
  }

  /**
   * When the verbalization item is for the footer, this method will set the footer information for the report based on
   * what that verbalization item provides.
   *
   * @param verbalizationFromPbev IVerbalization - This is the verbalization item that is currently being iterated over.
   * @param reportContent IVerbalization - This is the current reportModel that is being constructed by iterating over
   * the many verbalizations.
   *
   */
  public mapFooter(verbalizationFromPbev: IVerbalization, reportForProductPackage: Report): void {
    const footerValue = verbalizationFromPbev.attributes[0].value;
    reportForProductPackage.footerAttribute = reportForProductPackage.footerAttribute || [];
    if (isNotNullOrUndefined(footerValue)) {
      let valueContentAttribute;
      if ( footerValue.toLowerCase().indexOf('easyoptions') >= 0 ) {
        valueContentAttribute = {
          class: 'easy-options',
          value: footerValue,
        };
      } else {
        valueContentAttribute = {
          class: '',
          value: footerValue,
        };
      }
      reportForProductPackage.footerAttribute.push( valueContentAttribute );
    }
  }

  /**
   * When the verbalization item is for the header, this method will set the header information for the report based on
   * what that verbalization item provides.
   *
   * @param verbalizationFromPbev IVerbalization - This is the verbalization item that is currently being iterated over.
   * @param reportContent IVerbalization - This is the current reportModel that is being constructed by iterating over
   * the many verbalizations.
   *
   */
  public mapHeader(verbalizationFromPbev: IVerbalization, reportForProductPackage: Report): void {
    reportForProductPackage.header = reportForProductPackage.header || {};
    if (!verbalizationFromPbev.attributes) {
      reportForProductPackage.header.logo = verbalizationFromPbev.name;
    } else if (verbalizationFromPbev.name === 'report_Header') {
      reportForProductPackage.header.title = verbalizationFromPbev.attributes[0].value;
    } else if (!reportForProductPackage.prcReport) {
      reportForProductPackage.header[verbalizationFromPbev.name] =
        !verbalizationFromPbev.attributes ?
          {value: verbalizationFromPbev.name} :
          {
            label: verbalizationFromPbev.label ? verbalizationFromPbev.label + ' ' : undefined,
            value: verbalizationFromPbev.attributes[0].value
          };
    }
  }

  /**
   * When the verbalization item is for a benefit or memberGroup, this method will interrogate the verbalization item to
   * determine how to map the content of the verbalization to either the patient coverage (PRC) or patient identification
   * (non PRC) group, a line on that group, and a line item for the line of that group.
   *
   * @param verbalizationFromPbev IVerbalization - This is the verbalization item that is currently being iterated over.
   * @param reportContent IVerbalization - This is the current reportModel that is being constructed by iterating over
   * the many verbalizations.
   *
   */
  public mapBenefit(verbalizationFromPbev: IVerbalization, reportForProductPackage: Report): void {
    reportForProductPackage.groups = reportForProductPackage.groups || [];
    const groupLabel = reportForProductPackage.prcReport ? verbalizationFromPbev.groupLabel : 'Patient Identification';

    let group = reportForProductPackage.groups.find(g => g.label === groupLabel);
    if (!group) {
      group = {label: groupLabel, lines: []};
      reportForProductPackage.groups.push(group);
    }
    if (reportForProductPackage.prcReport) {
      let line = group.lines.find(l => l.label === 'Benefit');
      if (!line) {
        line = {label: 'Benefit', class: verbalizationFromPbev.label.replace(/ /g, '_').replace(/:/g, ''), items: []};
        group.lines.push(line);
        line.items.push({value: verbalizationFromPbev.attributes[0].value});
      } else {
        line.items.push({label: verbalizationFromPbev.label});
        line.items.push({value: verbalizationFromPbev.attributes[0].value});
      }
    } else {

      let line = group.lines[2];
      if (!line) {
        line = {class: verbalizationFromPbev.label.replace(/ /g, '_').replace(/:/g, ''), items: []};
        group.lines.push(line);
        line.items.push({label: verbalizationFromPbev.label.concat(':'), value: verbalizationFromPbev.attributes[0].value});
      } else {
        line.items.push({label: verbalizationFromPbev.label.concat(':'), value: verbalizationFromPbev.attributes[0].value});
      }
    }
  }

  /**
   * The verbalization item is interrogated to determine how to map the content of the verbalization to a group,
   * a line on the group, and a line item for the line of a group.
   *
   * @param verbalizationFromPbev IVerbalization - This is the verbalization item that is currently being iterated over.
   * @param reportContent IVerbalization - This is the current reportModel that is being constructed by iterating over
   * the many verbalizations.
   *
   */
  public mapGroup(verbalizationFromPbev: IVerbalization, reportContent: Report): void {
    if (this.verbalizedLineItemContentIsOnExclusionList(verbalizationFromPbev)) {
      return;
    }

    reportContent.groups = reportContent.groups || [];
    let lineItemContent: IReportGroupLineItem;
    const lineItemContents = [];
    if (verbalizationFromPbev.attributes.length === 1) {
      if (reportContent.prcReport) {
        lineItemContent = {
          label: '',
          value: verbalizationFromPbev.attributes[0].value
        };
      } else {
        const attributeValue = verbalizationFromPbev.attributes[0].value;
        let valueContentClass = '';
        if (attributeValue.toLowerCase().indexOf('easyoptions') >= 0 ) {
          valueContentClass = 'easy-options';
        }
        const valueContentLineItem = {
          class : valueContentClass,
          value : attributeValue
        };
        lineItemContent = {
          label: verbalizationFromPbev.attributes[0].type === 'SUB_LABEL' ? verbalizationFromPbev.label : '',
          value: attributeValue,
          valueItems: []
        };
        lineItemContent.valueItems.push(valueContentLineItem);
      }
    } else if (verbalizationFromPbev.attributes.length > 1) {
      const lineValues = verbalizationFromPbev.attributes.filter(attribute => attribute.type !== 'SUB_LABEL');
      if (verbalizationFromPbev.groupLabel === 'Contact Lenses' && verbalizationFromPbev.label === 'Coverage:') {
        let i = 0;
        let j = 0;
        verbalizationFromPbev.attributes.forEach((attribute) => {
          let lineValue  = '';
          if (j < lineValues.length) {
            lineValue = lineValues[j].value;
          }
          if (i % 2 === 0 && i <= verbalizationFromPbev.attributes.length) {
            lineItemContent = {
              label: attribute.value,
              class: 'sub-label',
              value: lineValue
            };
            lineItemContents.push(lineItemContent);
            j++;
          }
          i++;
        });
      } else if (verbalizationFromPbev.contentType === 'EasyOptions_Summary') {
        lineItemContent = {
          label: verbalizationFromPbev.attributes.filter(attrib => attrib.type === 'SUB_LABEL').length > 0
            ? verbalizationFromPbev.attributes.filter(attrib => attrib.type === 'SUB_LABEL').map(element => element.value).join('<br>')
            : '',
          class: 'easyOptionLabel',
          values: verbalizationFromPbev.attributes.filter(attrib => attrib.type === 'CONTENT').map(element => element.value)
        };
      } else if (verbalizationFromPbev.groupLabel === 'Lens Enhancement Details' && reportContent.prcReport) {
        lineValues.forEach((attribute) => {
          lineItemContent = {
            label: '',
            class: 'sub-label',
            value: attribute.value
          };
          lineItemContents.push(lineItemContent);
        });
      } else if (verbalizationFromPbev.groupLabel === 'Plan Details' && reportContent.prcReport) {
        lineItemContent = {
          label: verbalizationFromPbev.attributes[0]?.value,
          value: lineValues.map(element => element.value).join(' ')
        };
      } else {
        const elementValue = lineValues.map(element => element.value).join(' ');
        lineItemContent = {
          label: verbalizationFromPbev.attributes[0]?.value,
          class: 'sub-label',
          value: elementValue,
          valueItems: []
        };
      }
    }

    let group = reportContent.groups.find(g => g.label === verbalizationFromPbev.groupLabel);
    if (!group) {
      group = {
        label: verbalizationFromPbev.groupLabel,
        class: verbalizationFromPbev.groupLabel.replace(/ /g, '_').replace(/:/g, ''),
        lines: []
      };
      reportContent.groups.push(group);
    }

    if (verbalizationFromPbev.name.toLowerCase().indexOf('footnote') > -1) {
      group.footnote = verbalizationFromPbev.attributes[0].value;
      let footnoteAttribute;
      if ( group.footnote.toLowerCase().indexOf('easyoptions') >= 0 ) {
        footnoteAttribute = {
          class: 'easy-options',
          value: group.footnote,
        };
        group.footnoteAttributes = footnoteAttribute;
      } else {
        footnoteAttribute = {
          class: '',
          value: group.footnote,
        };
        group.footnoteAttributes = footnoteAttribute;
      }
      return;
    }

    let line = group.lines.find(l => l.label === (verbalizationFromPbev.label || lineItemContent.label));
    if (!line) {
      if (verbalizationFromPbev.contentType === 'EasyOptions_Summary') {
        line = {
          label: (verbalizationFromPbev.label || lineItemContent.label),
          class: 'Easy_Options_Summary',
          innerTag: 'easyOptionsHeader',
          items: []
        };
      } else {
        line = {
          label: (verbalizationFromPbev.label || lineItemContent.label),
          class: (verbalizationFromPbev.label || lineItemContent.label).replace(/ /g, '_').replace(/:/g, ''),
          items: []
        };
      }
      group.lines.push(line);
    }

    const subLabelItem =
      line.items.find(item => item.class === 'sub-label' && item.label === lineItemContent.label && item.class === lineItemContent.class);
    if (subLabelItem) {
      let valueContentClass = '';
      const lineItemContentValue = lineItemContent.value;
      if (lineItemContentValue.toLowerCase().indexOf('easyoptions') >= 0 ) {
        valueContentClass = 'second-easy-option';
      }
      const valueContentSubItem = {
        class : '',
        value : subLabelItem.value
      };

      const valueContentLineItem = {
        class : valueContentClass,
        value : lineItemContentValue
      };

      if (!subLabelItem.values) {
        subLabelItem.values = [];
        subLabelItem.valueItems.push(valueContentSubItem);
      }
      subLabelItem.value = undefined;
      subLabelItem.valueItems.push(valueContentLineItem);
    } else {
      verbalizationFromPbev.attributes.length > 1 &&
            ((verbalizationFromPbev.groupLabel === 'Contact Lenses' && verbalizationFromPbev.label === 'Coverage:') ||
              (verbalizationFromPbev.groupLabel === 'Lens Enhancement Details' && reportContent.prcReport)) ?
        line.items.push(...lineItemContents) : line.items.push(lineItemContent);
    }
  }

  public piMapper(payload: IRetailPatientEligibilityResponse, productPackage: IProductPackage, vsrNumber: string): IReportGroup {
    return payload.prcReport ?
      this.prcPiMapper(payload, productPackage, vsrNumber) :
      this.eligibilityPiMapper(payload, productPackage, vsrNumber);
  }

  public isWalmartVisionSavingsPass(payload: IRetailPatientEligibilityResponse, productPackage: IProductPackage): boolean {
    let processedIsWalmartVisionSavingsPass = false;
    if (isNotNullOrUndefined(payload) && isNotNullOrUndefined(productPackage) && isNotNullOrUndefined(productPackage.name)
      && isNotNullOrUndefined(payload.reservations) && payload.reservations.length !== 0
      && isNotNullOrUndefined(payload.retailProviderNetworks) && payload.retailProviderNetworks.length !== 0
      && isNotNullOrUndefined(payload.retailProviderNetworks[0].networkId)) {
      const currentReservation = payload.reservations.find((rsv: IReservation) => rsv.productName === productPackage.name);
      processedIsWalmartVisionSavingsPass = (
        isNotNullOrUndefined(currentReservation)
        && isNotNullOrUndefined(currentReservation.productName)
        && currentReservation.productName === 'VSPVisionSavingsPass'
        && (payload.retailProviderNetworks[0].networkId === 'WALM' || payload.retailProviderNetworks[0].networkId === 'WALMQ')
      );
    }
    return processedIsWalmartVisionSavingsPass;
  }

  public eligibilityPiMapper(payload: IRetailPatientEligibilityResponse, productPackage: IProductPackage, vsrNumber: string): IReportGroup {
    const currentReservation = vsrNumber ? null :
      payload.reservations.find((rsv: IReservation) => rsv.productName === productPackage.name);
    return {
      label: 'Patient Identification',
      value: {
        label: (vsrNumber || (currentReservation && currentReservation.vsrnumber)) ? 'Authorization#' : '',
        value: (vsrNumber ? vsrNumber : currentReservation ? currentReservation.vsrnumber : '') as string
      },
      class: 'Patient_Identification',
      lines: [
        {
          items: [
            {
              label: payload.verbalizations[0] ? payload.verbalizations[0].attributes[0].value : '',
              value: payload.verbalizations[0] ? payload.verbalizations[0].attributes[1].value : '',
            },
            {
              label: payload.verbalizations[1] ? payload.verbalizations[1].attributes[0].value : '',
              value: payload.verbalizations[1] ? payload.verbalizations[1].attributes[1].value : ''
            },
            {
              label: payload.verbalizations[2] ? payload.verbalizations[2].attributes[0].value : '',
              value: payload.verbalizations[2] ? payload.verbalizations[2].attributes[1].value : ''
            }
          ]
        },
        {
          items: [
            {
              label: payload.verbalizations[3] ? payload.verbalizations[3].attributes[0].value : '',
              value: payload.verbalizations[3] ? payload.verbalizations[3].attributes[1].value : ''
            },
            {
              label: payload.verbalizations[4] ? payload.verbalizations[4].attributes[0].value : '',
              value: payload.verbalizations[4] ? payload.verbalizations[4].attributes[1].value : ''
            }
          ]
        }
      ]
    } as IReportGroup;
  }

  public prcPiMapper(payload: IRetailPatientEligibilityResponse, productPackage: IProductPackage, vsrNumber: string): IReportGroup {
    const currentReservation = vsrNumber ? null :
      payload.reservations.find((rsv: IReservation) => rsv.productName === productPackage.name);
    return {
      label: 'Patient Identification',
      class: 'Patient_Identification',
      lines: [
        {
          items: [
            {
              label: payload.verbalizations[0] ? payload.verbalizations[0].attributes[0].value : '',
              value: payload.verbalizations[0] ? payload.verbalizations[0].attributes[1].value : '',
            },
            {
              label: (vsrNumber || (currentReservation && currentReservation.vsrnumber)) ? 'Authorization#' : '',
              value: (vsrNumber ? vsrNumber : currentReservation ? currentReservation.vsrnumber : '') as string
            }
          ]
        },
        {
          items: [
            {
              label: payload.verbalizations[1] ? payload.verbalizations[1].attributes[0].value : '',
              value: payload.verbalizations[1] ? payload.verbalizations[1].attributes[1].value : '',
            },
            {
              label: payload.verbalizations[2] ? payload.verbalizations[2].attributes[0].value : '',
              value: payload.verbalizations[2] ? payload.verbalizations[2].attributes[1].value : '',
            }
          ]
        },
        {
          items: [
            {
              label: payload.verbalizations[3] ? payload.verbalizations[3].attributes[0].value : '',
              value: payload.verbalizations[3] ? payload.verbalizations[3].attributes[1].value : '',
            },
            {
              label: payload.verbalizations[4] ? payload.verbalizations[4].attributes[0].value : '',
              value: payload.verbalizations[4] ? payload.verbalizations[4].attributes[1].value : '',
            }
          ]
        }
      ]
    } as IReportGroup;
  }

  public mapReports(responseFromRPE: IRetailPatientEligibilityResponse, productPackagesFromPBEV: IProductPackage[], vsrNumber: string): Reports {
    const mappedReports: Reports = {
      // Per RETAIL-593, we added the below check on the productPackagesFromPBEV
      // to avoid the null pointer/ undefined exception being thrown.
      packages: (productPackagesFromPBEV || []).map((productPackage: IProductPackage) => {
        const patientInformationReportGroup: IReportGroup = this.piMapper(responseFromRPE, productPackage, vsrNumber);
        const reportForProductPackage: Report = {
          groups: [patientInformationReportGroup],
          prcReport: responseFromRPE.prcReport,
          isWalmVisionSavingsPass: this.isWalmartVisionSavingsPass(responseFromRPE, productPackage),
        };
        productPackage.verbalizations.map((verbalizationFromPbev: IVerbalization) => {
          this.mapVerbalizationsFromProductPackageToReport(verbalizationFromPbev, reportForProductPackage);
        });
        return reportForProductPackage;
      }),
      prcReport: responseFromRPE.prcReport
    };
    this.sortPlanDetails(mappedReports);
    mappedReports.packages.forEach(prod => prod.groups.forEach(group => {
      group.lines.forEach(line => {
        if (line.items.filter(item => item.class === 'sub-label').length > 0 && !line.innerTag) {
          line.isSubLine = true;
        }
      });
    }));
    return mappedReports;
  }

  private verbalizationItemHasFooterContent(verbalizationFromPbev: IVerbalization, isPrcReport: boolean): boolean {
    return (verbalizationFromPbev.groupName === 'additionalInformation' && !isPrcReport);
  }

  private verbalizationItemHasHeaderContent(verbalizationFromPbev: IVerbalization): boolean {
    return (verbalizationFromPbev.groupName === 'reportHeader');
  }

  private verbalizationItemHasBenefitContent(verbalizationFromPbev: IVerbalization): boolean {
    return (verbalizationFromPbev.name === 'benefit' || verbalizationFromPbev.name === 'membergroup');
  }

  private verbalizationItemHasGroupLabelAndGroupName(verbalizationFromPbev: IVerbalization): boolean {
    return !isStringNullUndefinedOrEmpty(verbalizationFromPbev.groupLabel) &&
              !isStringNullUndefinedOrEmpty(verbalizationFromPbev.groupName);
  }

  /**
   * This method checks if the verbalization item that is being iterated over while building the report object used for
   * displaying the patient eligibility report contains data that we want to exclude from being displayed on the patient
   * eligibility report.
   *
   * @param verbalizationFromPbev IVerbalization - This is the current verbalization item that is being iterated over
   * when generating the report object.
   *
   */
  private verbalizedLineItemContentIsOnExclusionList(verbalizationFromPbev: IVerbalization): boolean {
    return isNotNullOrUndefined(verbalizationFromPbev.attributes) &&
      verbalizationFromPbev.attributes.length > 0 &&
      verbalizationFromPbev.attributes[0].type === 'SUB_LABEL' &&
      PatientEligibilityReportConstants.EXCLUDE_VERBALIZATION_BY_SUB_LABEL.includes(verbalizationFromPbev.attributes[0].value);
  }

  /**
   * This method sorts the lines of information on the plan details group for each report to be displayed on the patient
   * eligibility report.
   *
   * @param mappedReports Reports - This is the already mapped reports object that needs to have each report's (package)
   * group interrogated to see if it is for plan details, then sort the lines for the plan details group.
   *
   */
  private sortPlanDetails(mappedReports: Reports): void {
    mappedReports.packages.map( (mappedReport: Report) => {
      mappedReport.groups.map( (group: IReportGroup) => {
        if (group.label === 'Plan Details') {
          group.lines.sort((firstElement, secondElement) => {
            const firstElementIndex: number = this.getPlanDetailLineOrderIndex(firstElement.label.toLowerCase());
            const secondElementIndex: number = this.getPlanDetailLineOrderIndex(secondElement.label.toLowerCase());
            if (firstElementIndex < secondElementIndex) {
              return -1;
            }
            if (firstElementIndex > secondElementIndex) {
              return 1;
            }
            return 0;
          });
        }
      });
    });
  }

  /**
   * This method returns the index that the label of a plan details verbalization item matched to based on the plan
   * details line order specified in the constants. The plan details line order is an array of objects that specify the
   * sub string that should be included in the verbalization label, and the option to include a sub string that should be
   * excluded from the match on the verbalization label. The lower the number the index returned, the higher in the report
   * that verbalization should display. If there is no match on the verbalization label against any of the entries in the
   * plan detail line order, then the largest number (the length of the plan detail line order array) is used as the index,
   * which forces the verbalization to the bottom of the report.
   *
   * @param elementLabel string - The label of the plan detail type verbalization item.
   *
   * @return number - The decided value in the plan detail line order hierarchy that will be used to determine where to
   * sort the plan detail verbalization.
   *
   */
  private getPlanDetailLineOrderIndex(elementLabel: string): number {
    const planDetailsLineOrder: PlanDetailOrder[] = PatientEligibilityReportConstants.PLAN_DETAILS_LINE_ORDER;
    let planDetailLineOrderIndex: number = planDetailsLineOrder.length;
    for (let index = 0; index < planDetailsLineOrder.length; index++) {
      const planDetailLineAtIndex: PlanDetailOrder = planDetailsLineOrder[index];
      if (elementLabel.includes(planDetailLineAtIndex.includes) &&
        (isStringNullUndefinedOrEmpty(planDetailLineAtIndex.excludes) || !elementLabel.includes(planDetailLineAtIndex.excludes))) {
        planDetailLineOrderIndex = index;
        break;
      }
    }
    return planDetailLineOrderIndex;
  }

}
